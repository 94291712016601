import { useNavigate } from '@tanstack/react-router';
import { ReactComponent as ErrorIcon } from 'images/icons/ErrorSign.svg';
import Typography from '@mui/material/Typography';
import ButtonBase from 'common/components/buttons/FormButton';
import { StyledNoViewPermissions } from './style';
import useTexts from './useTexts';

export default function NoViewPermissions({ title, text }) {
  const navigate = useNavigate();
  const texts = useTexts();
  const handleClick = () => {
    navigate({ to: '/spaces' });
  };
  return (
    <StyledNoViewPermissions>
      <ErrorIcon />
      <Typography variant="h1">{title}</Typography>
      <Typography>{text}</Typography>
      <ButtonBase
        style={{ marginTop: '20px' }}
        btnTheme="primary"
        text={texts.viewPermissionsButtonText}
        onClick={handleClick}
      />
    </StyledNoViewPermissions>
  );
}
