import { useRef, useEffect, useState, useCallback, ReactNode, FC } from 'react';
import { useDispatch } from 'react-redux';
import { StyledTicketSummaryTooltip } from 'common/components/controls/Tooltip/Tooltip';
import { setIsSummaryTooltipOpen } from 'store/ticketSummarySlice';
import { useTicketSummary } from 'common/utils/hooks/useTicketSummary';

interface TicketSummaryTooltipProps {
  children: ReactNode;
  isWrappedInputFocused: boolean;
  isAiEnabled?: boolean;
  assistiveAI?: {
    AICaseSummarizations?: boolean;
    AIEmotions?: boolean;
  };
}

/**
 * A tooltip component that displays ticket summary information when hovering over elements
 *
 * The tooltip contains AI-generated summaries of ticket information and is controlled
 * by hover state and focus state of the wrapped input.
 *
 * @param children - Child elements to be wrapped by the tooltip
 * @param isWrappedInputFocused - Whether the input wrapped by the tooltip is focused
 * @param isAiEnabled - Whether AI features are enabled
 * @param assistiveAI - Configuration object for AI features
 * @param assistiveAI.AICaseSummarizations - Whether AI case summarization is enabled
 * @param assistiveAI.AIEmotions - Whether AI emotions analysis is enabled
 * @returns A tooltip component with ticket summary
 */
export const TicketSummaryTooltip: FC<TicketSummaryTooltipProps> = ({
  children,
  isWrappedInputFocused,
  isAiEnabled,
  assistiveAI,
}) => {
  const dispatch = useDispatch();
  const popperRef = useRef<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ticketSummaryPopup = useTicketSummary(popperRef);

  const handleOpen = useCallback((): void => {
    setIsOpen(true);
    dispatch(setIsSummaryTooltipOpen(true));
  }, [dispatch]);

  const handleClose = useCallback((): void => {
    setIsOpen(false);
    dispatch(setIsSummaryTooltipOpen(false));
  }, [dispatch]);

  useEffect(() => {
    // Force closing the tooltip on focus, since disabling hoverListener
    // and FocusListener doesn't close the popup while hovering the input
    if (isWrappedInputFocused && isOpen) {
      handleClose();
    }
  }, [handleClose, isOpen, isWrappedInputFocused]);

  useEffect(() => () => handleClose(), [handleClose]);

  const enableAiSummary = assistiveAI?.AICaseSummarizations || assistiveAI?.AIEmotions;
  const disableHoverListener = isWrappedInputFocused || !isAiEnabled || !enableAiSummary;

  return (
    <StyledTicketSummaryTooltip
      title={ticketSummaryPopup}
      PopperProps={{
        popperRef,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
            },
          ],
        },
      }}
      placement="bottom"
      arrow
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      disableFocusListener
      disableHoverListener={disableHoverListener}
    >
      <div data-testid="summary-tooltip" className="summary-tooltip">
        {children}
      </div>
    </StyledTicketSummaryTooltip>
  );
};
