import { List, ListItemAvatar, ListItemText } from '@mui/material';
import BasicAvatar from 'common/components/avatars/basicAvatar';
import { AVATAR_CONSTANTS } from 'constants/index';
import Tooltip from 'common/components/tooltip';
import PropTypes from 'prop-types';
import { StyledUserInfoCardTeamDetails } from './styles';
import useTexts from '../useText';

const componentId = 'user-info-card-team-details';

const UserInfoCardTeamDetails = ({ teamName, memberCount, isUserCard }) => {
  const { membersText } = useTexts();

  return (
    <>
      <StyledUserInfoCardTeamDetails id={componentId} disablePadding isUserCard={isUserCard}>
        <ListItemAvatar id={`${componentId}-avatar`}>
          <BasicAvatar
            type={AVATAR_CONSTANTS.TYPES.GROUP}
            size={AVATAR_CONSTANTS.SIZES.BIG}
            disableHover
            isUserDetails
          />
        </ListItemAvatar>
        <List id={`${componentId}-content`}>
          <ListItemText
            id={`${componentId}-text`}
            primaryTypographyProps={{ id: `${componentId}-text-primary` }}
            primary={<Tooltip
              className="primary-text"
              isTruncatedText
              text={teamName}
            />}
          />
          <ListItemText
            id={`${componentId}-members-count`}
            primaryTypographyProps={{ id: `${componentId}-text-secondary`}}
            primary={`${memberCount} ${membersText}`}
          />
        </List>
      </StyledUserInfoCardTeamDetails>
    </>
  );
};

export default UserInfoCardTeamDetails;

UserInfoCardTeamDetails.propTypes = {
  teamName: PropTypes.string,
  memberCount: PropTypes.number,
  isUserCard: PropTypes.bool
};
