import { useState, useEffect, useRef, memo, useCallback, useMemo } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StyledLabelWithEllipsis } from './styles';

export const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    maxWidth: '381px',
    alignItems: 'flex-start',
    gap: '10px',
    padding: '12px',
    borderRadius: '10px',
    backgroundColor: '#494949',
    overflowWrap: 'break-word',
    justifyContent: 'center',
    fontFamily: 'Roboto-Regular',
    fontSize: '12px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: '#fff',
    marginBottom: (props) => (props.placement?.includes('top') ? '10px!important' : undefined),
    marginLeft: (props) => (props.placement === 'right' ? '22px!important' : undefined),
    textAlign: (props) => props.textAlign || 'center',

    '& .current-assignee': {
      color: '#fff !important',
    },
  },
  arrow: {
    color: '#494949',
  },
}));

function Tooltip(props) {
  const {
    text,
    isTruncatedText,
    id,
    title,
    style,
    disableInteractive = false,
    disableHoverListener = false,
    disableFocusListener = false,
    placeholder,
    forceShowOnHover,
    dataset,
    displayHomeIcon,
    isError,
    className,
    disableTooltip,
    placement,
    ...rest
  } = props;

  const tooltipClass = useStylesBootstrap({ placement, ...props });
  const [showTooltip, setShowTooltip] = useState(!isTruncatedText);
  const elementRef = useRef();

  const tooltipTitle = useMemo(() => title || text, [title, text]);
  const isTooltipHidden = useMemo(() => disableTooltip || !showTooltip, [disableTooltip, showTooltip]);

  const checkIfHaveEllipsis = useCallback(() => {
    if (!elementRef.current) return;
    const isTextEllipsis = elementRef.current.scrollWidth > elementRef.current.clientWidth;
    setShowTooltip(Boolean(isTextEllipsis || forceShowOnHover));
  }, [forceShowOnHover]);

  const handleMouseEnterAndLeaveRef = useCallback(() => {
    checkIfHaveEllipsis();
  }, [checkIfHaveEllipsis]);

  useEffect(() => {
    if (!isTruncatedText) {
      setShowTooltip(true);
    }
  }, [isTruncatedText]);

  const popperProps = useMemo(
    () => ({
      className: isError ? 'error-tooltip' : '',
    }),
    [isError],
  );

  const tooltipStyles = useMemo(
    () => ({
      fontSize: !text && placeholder ? '14px' : 'inherit',
      ...style,
    }),
    [text, placeholder, style],
  );

  return (
    <MuiTooltip
      {...rest}
      {...dataset}
      title={isTooltipHidden ? null : tooltipTitle}
      disableHoverListener={!showTooltip || disableHoverListener}
      arrow
      aria-label={tooltipTitle}
      classes={tooltipClass}
      sx={tooltipStyles}
      disableInteractive={disableInteractive}
      disableFocusListener={disableFocusListener}
      data-error-tooltip={isError}
      PopperProps={popperProps}
      enterDelay={500}
      enterNextDelay={500}
    >
      {isTruncatedText ? (
        <StyledLabelWithEllipsis
          variant="span"
          ref={elementRef}
          onMouseEnter={handleMouseEnterAndLeaveRef}
          onMouseLeave={handleMouseEnterAndLeaveRef}
          className={className}
          data-field-type="text-with-tooltip"
          id={id}
          text={text}
        >
          {text ?? placeholder}
        </StyledLabelWithEllipsis>
      ) : (
        <span id={id} className={className} data-field-type="text-with-tooltip">
          {text}
        </span>
      )}
    </MuiTooltip>
  );
}

export default memo(Tooltip);
