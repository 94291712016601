import { useEffect } from 'react';
import { useRouter } from '@tanstack/react-router';

interface PendoGuide {
  id: string;
  dismiss?: () => void;
}

interface PendoWindow extends Window {
  pendo?: {
    onGuideAdvanced: (callback: ((guide: PendoGuide) => void) | null) => void;
    onGuideDismissed: (callback: ((guide: PendoGuide) => void) | null) => void;
    clearGuide: () => void;
  };
}

declare const window: PendoWindow;

/**
 * Component to manage Pendo guide lifecycle and prevent memory leaks
 * This component should be rendered inside RouterProvider where router context is available
 */
export const PendoGuideManager: React.FC = () => {
  const router = useRouter();
  const currentPath = router.state.location.pathname;

  useEffect(() => {
    // Skip if pendo is not initialized
    if (!window.pendo) {
      return;
    }

    const mountedGuides = new Set<PendoGuide>();

    // Track guide mounting
    const handleGuideMount = (guide: PendoGuide): void => {
      if (guide && guide.id) {
        mountedGuides.add(guide);
      }
    };

    // Track guide dismissal
    const handleGuideDismiss = (guide: PendoGuide): void => {
      if (guide && guide.id) {
        mountedGuides.delete(guide);
        // Force cleanup of any detached DOM elements
        const guideElements = document.querySelectorAll(`[data-pendo-guide-id="${guide.id}"]`);
        guideElements.forEach((element) => {
          element.remove();
        });
      }
    };

    // Subscribe to Pendo events
    window.pendo.onGuideAdvanced(handleGuideMount);
    window.pendo.onGuideDismissed(handleGuideDismiss);

    // Cleanup function
    return () => {
      // Cleanup all mounted guides
      mountedGuides.forEach((guide) => {
        if (guide && typeof guide.dismiss === 'function') {
          guide.dismiss();
        }
      });
      mountedGuides.clear();

      // Remove event listeners
      if (window.pendo) {
        window.pendo.onGuideAdvanced(null);
        window.pendo.onGuideDismissed(null);
        window.pendo.clearGuide();
      }

      // Force cleanup any remaining guide elements
      const allGuideElements = document.querySelectorAll('[data-pendo-guide]');
      allGuideElements.forEach((element) => {
        element.remove();
      });
    };
  }, [currentPath]); // Re-run on route changes to ensure cleanup

  return null; // This component doesn't render anything
};
