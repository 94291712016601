import { ReactComponent as WarningIcon } from 'images/icons/WarningSign.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/ErrorSign.svg';
import { cancelPrompt, selectMessagePrompt } from 'store/messagePromptSlice';
import { useDispatch, useSelector } from 'react-redux';

import { FloatingTicketInstanceModes } from 'features/floatingTicketPanel/floatingTicketPanelManager/modes';
import { LIMITED_DATA_BY_COMPANY_ALL_FIELDS } from 'features/settings/constants';
import {
  closeFloatingTicketPanel,
  editFloatingTicketPanel,
  toggleAutoPopulateSection,
} from 'store/floatingTicketPanelSlice';
import useTexts from './useTexts';
import MessagePrompt from '.';

export default function MessagePromptWrapper() {
  const dispatch = useDispatch();
  const { titleText, descriptionText, btnOkText, btnCancelText } = useTexts();
  const prompt = useSelector(selectMessagePrompt);
  const showIcon = !!prompt?.showIcon;

  const onCancelClicked = () => {
    dispatch(cancelPrompt());
  };

  const onOkClick = () => {
    switch (prompt.onClickFunctionName) {
      case closeFloatingTicketPanel.toString():
        dispatch(closeFloatingTicketPanel(prompt?.url));
        onCancelClicked();
        break;
      case toggleAutoPopulateSection.toString(): {
        const { floatingPanelMode, floatingPanelId } = prompt;
        dispatch(toggleAutoPopulateSection(false));
        dispatch(
          editFloatingTicketPanel({
            id: floatingPanelId,
            mode:
              floatingPanelMode === FloatingTicketInstanceModes.AUTO_POPULATE
                ? FloatingTicketInstanceModes.REGULAR_MODE_OPENED
                : FloatingTicketInstanceModes.EXPANDED,
          }),
        );
        prompt?.okCb?.();
        onCancelClicked();
        break;
      }
      case LIMITED_DATA_BY_COMPANY_ALL_FIELDS: {
        prompt?.okCb?.();
        onCancelClicked();
        break;
      }
      default:
        dispatch(cancelPrompt());
        break;
    }
  };

  const icon = showIcon ? ErrorIcon : WarningIcon;

  return (
    <>
      {prompt && (
        <MessagePrompt
          open
          showIcon={showIcon}
          title={prompt?.title || titleText}
          Icon={icon}
          btnOkText={prompt?.btnOkText || btnOkText}
          btnCancelText={prompt?.btnCancelText || btnCancelText}
          onOkClick={onOkClick}
          showCancelBtn={typeof prompt.showCancelBtn === 'undefined' ? true : prompt.showCancelBtn}
          onClose={() => {
            prompt?.cancelCb?.();
            onCancelClicked();
          }}
        >
          {prompt?.descriptionText || descriptionText}
        </MessagePrompt>
      )}
    </>
  );
}
