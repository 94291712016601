import React, { useState, useRef, useEffect, memo } from 'react';
import { Box, Popper } from '@mui/material';
import UserInfoCard from 'common/components/userInfoCard';
import { useAssigneesList, useUserDetailsById } from 'remote-state/ticketServiceHooks';

const User = ({ userId, teamName, children, customSx, popper }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const hoverTimeout = useRef(null);
  const { data: user } = useUserDetailsById(userId);

  const {
    adminsByGroup: { data: teamMembers },
  } = useAssigneesList(teamName, user?.groups);

  const team = {
    teamName: teamName || user?.groups?.[0],
    teamMembers,
  };

  const ref = useRef(null);
  const toShowDetails = !!anchorEl;

  const hideDetails = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    hoverTimeout.current = setTimeout(() => setAnchorEl(null), 200);
  };

  const showDetails = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    hoverTimeout.current = setTimeout(() => setAnchorEl(ref.current), 500);
  };

  useEffect(
    () => () => {
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
    },
    [],
  );

  return (
    <Box ref={ref} onClick={hideDetails} onMouseLeave={hideDetails} sx={customSx} data-testid="user-component">
      {React.cloneElement(children, {
        onMouseEnter: showDetails,
      })}
      <Popper
        open={toShowDetails}
        anchorEl={anchorEl}
        placement={popper?.placement || 'bottom-start'}
        sx={popper?.sx}
        onClick={(e) => e.stopPropagation()}
      >
        <Box>
          <UserInfoCard user={user} team={team} />
        </Box>
      </Popper>
    </Box>
  );
};

export default memo(User);
