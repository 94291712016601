import { useQuery } from '@tanstack/react-query';
import { QUERY_STALE_TIME } from 'constant';
import { getOpenaiSummarizeTicket } from 'services/sysaiService';

const isValidId = (srId) => typeof srId === 'number' && srId >= 0 && !Number.isNaN(srId);

export const useTicketSummaryByIdQuery = (srId) =>
  useQuery({
    queryKey: ['ticketSummary', srId],
    queryFn: () => getOpenaiSummarizeTicket(srId),
    cacheTime: QUERY_STALE_TIME.NONE,
    staleTime: QUERY_STALE_TIME.NONE,
    enabled: isValidId(srId),
  });
